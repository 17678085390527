/* global moment, config, angular, store */

const CREATION_COMPTE_PROFESSIONNEL_PARTENAIRE = 'creation-compte-professionnel';
const CERTIFICAT_IMMATRICULATION = 'certificat-immatriculation';
const CERTIFICAT_IMMATRICULATION_WW = 'certificat-immatriculation-ww';
const DUPLICATA = 'duplicata';
const VENTE_EN_LIGNE = 'vente-en-ligne';
const DECLARATION_ACHAT = 'declaration-achat';
const DECLARATION_CESSION = 'declaration-cession';
const CERTIFICAT_SITUATION_ADMINISTRATIVE = 'certificat-situation-administrative';
const PLAQUES_IMMATRICULATION = 'plaques-immatriculation';
const CHANGEMENT_ADRESSE = 'changement-adresse';
const FICHE_IDENTIFICATION_VEHICULE = 'fiche-identification-vehicule';
const CERTIFICAT_IMMATRICULATION_VN = 'certificat-immatriculation-vn';
const CERTIFICAT_IMMATRICULATION_VI = 'certificat-immatriculation-vi';

const DEFAULT_ERROR_MESSAGE = 'Une erreur est survenue lors du traitement de votre demande.';

const PERSONNE_PHYSIQUE = 'personne-physique';
const PERSONNE_MORALE = 'personne-morale';

//config.isPartnerSession = true;

const EVENTS = {
    COMPONENT_LOAD: 'component-load',
    NAVIGATION: 'navigation'
};
const PROCEDURES_WITH_COBUYERS = [
    CERTIFICAT_IMMATRICULATION,
    CERTIFICAT_IMMATRICULATION_WW,
    CERTIFICAT_IMMATRICULATION_VI
];

function getStepLabel(step) {
    switch (step) {
        case 'start':
            return 'Nouvelle démarche';
        case 'seller':
            return 'Le vendeur';
        case 'buyer':
            return "L'acquéreur";
        case 'owner':
            return 'Le propriétaire';
        case 'vehicle':
            return 'Le véhicule';
        case 'payment':
            return 'Le paiement';
        case 'questions':
            return 'Les questions';
        case 'documents':
            return 'Les documents';
        case 'signature':
            return 'La signature';
        case 'end':
            return 'Fin de démarche';
    }
}

function getStepIcon(step) {
    switch (step) {
        case 'start':
            return 'folder_special';
        case 'seller':
        case 'buyer':
        case 'owner':
            return 'person';
        case 'cobuyers':
            return 'group';
        case 'vehicle':
            return 'directions_car';
        case 'payment':
            return 'payments';
        case 'questions':
            return 'help';
        case 'documents':
            return 'folder';
        case 'signature':
            return 'edit_document';
        case 'end':
            return null;
    }
    return null;
}

function setStepAsDone(procedureSteps, step) {
    let nextStep = null;
    for (let i = 0; i < procedureSteps.length; i++) {
        let procedureStep = procedureSteps[i];
        if (procedureStep.name === step) {
            procedureStep.done = true;
            if (i < procedureSteps.length - 1) {
                nextStep = procedureSteps[i + 1]
            }
            return {
                procedureSteps: procedureSteps,
                nextStep: nextStep
            };
        }
    };
    return {
        procedureSteps: procedureSteps,
        nextStep: nextStep
    };
}

function getProcedureSteps(procedureType) {
    let procedureSteps = [];
    if (!procedureType) {
        return null;
    }
    const procedureStepsNames = getProcedureStepsNames(procedureType);
    let i = 0;
    procedureStepsNames.forEach((step) => {
        procedureSteps.push({
            index: i,
            name: step,
            label: getStepLabel(step),
            icon: getStepIcon(step),
            done: false
        });
        i++;
    });
    return procedureSteps;
}

function getProcedureStepsNames(procedureType) {
    switch (procedureType) {
        case 'certificat-immatriculation':
            return [
                'start', 'seller', 'buyer', 'vehicle', 'payment', 'questions', 'documents', 'signature', 'end'
            ];
        case 'changement-adresse':
            return [
                'start', 'owner', 'vehicle', 'questions', 'documents', 'payment', 'signature', 'end'
            ];
        case 'duplicata':
            return [
                'start', 'owner', 'vehicle', 'payment', 'questions', 'documents', 'signature', 'end'
            ];
        case 'declaration-cession':
            return [
                'start', 'seller', 'buyer', 'vehicle', 'documents', 'signature', 'payment', 'end'
            ];
        case 'declaration-achat':
            return [
                'start', 'seller', 'buyer', 'vehicle', 'documents', 'signature', 'payment', 'end'
            ];
        case 'certificat-immatriculation-ww':
            return [
                'start', 'buyer', 'questions', 'documents', 'payment', 'signature', 'end'
            ];
        case 'certificat-immatriculation-vi':
            return [
                'start', 'buyer', 'questions', 'documents', 'payment', 'signature', 'end'
            ];
        case 'certificat-situaton-administrative':
            return [
                'start', 'owner', 'vehicle', 'payment', 'end'
            ];
        case 'fiche-identification-vehicule':
            return [
                'start', 'owner', 'vehicle', 'documents', 'payment', 'end'
            ];
    };
    return [];
}

//------------------------------------------------------------------------------

function getProcedureQuestions(procedureType) {
    let questions = [
        {
            title: "Le client a-t-il un ancien permis ?",
            description: "Si le client a eu son permis de conduire avant 2013 ...",
            selectedValue: null,
            availableForPro: false,
            notAvailableForVehicles: [8, 9, 10],
            modelName: 'buyerHasOldPermis',
            answers: [
                { type: 'button', label: 'Oui', value: true, yes: true },
                { type: 'button', label: 'Non', value: false, no: true }
            ]
        },
        {
            title: "Le client est-t-il hébergé ?",
            description: "Si le client est hébergé par un parent, un ami, un tiers, ...",
            selectedValue: null,
            availableForPro: false,
            modelName: 'buyerIsHosted',
            answers: [
                { type: 'button', label: 'Oui', value: true, yes: true },
                { type: 'button', label: 'Non', value: false, no: true }
            ]
        },
        {
            title: "Le véhicule a-t-il été acheté à un professionnel de l'automobile ?",
            description: "Si le véhicule a été acheté d'un garage ou d'un négociant, ...",
            selectedValue: null,
            availableForPro: true,
            modelName: 'vehicleFromPro',
            answers: [
                { type: 'button', label: 'Oui', value: true, yes: true },
                { type: 'button', label: 'Non', value: false, no: true }
            ]
        },
        {
            title: "Le véhicule est-t-il déjà assuré ?",
            description: "Si ce n'est pas le cas, le client s'engage à le faire ultérieurement.",
            selectedValue: null,
            availableForPro: true,
            modelName: 'vehicleIsInsured',
            answers: [
                { type: 'button', label: 'Oui', value: true, yes: true },
                { type: 'button', label: 'Non', value: false, no: true }
            ]
        },
        {
            title: "Existe-t-il un(des) coacquéreur(s) pour ce véhicule ?",
            description: "Si le client est l'acquéreur principal et il veut inscrire une personne supplémentaire sur la nouvelle carte grise.",
            selectedValue: null,
            availableForPro: true,
            modelName: 'hasCoBuyers',
            answers: [
                { type: 'button', label: 'Oui', value: true, yes: true },
                { type: 'button', label: 'Non', value: false, no: true }
            ]
        },
        {
            title: "Le client a-t-il déjà déclaré 3 changements d'adresse pour ce véhicule et il veut en déclarer un 4e ?",
            description: "Si le client fait la déclaration de changement d'adresse pour la 4ème fois.",
            selectedValue: null,
            availableForPro: true,
            modelName: 'has4Changes',
            answers: [
                { type: 'button', label: 'Oui', value: true, yes: true },
                { type: 'button', label: 'Non', value: false, no: true }
            ]
        },
        {
            title: "Pour quel motif le client fait-il la demande de duplicata ?",
            description: "Si la carte grise est perdue, volée, ou détériorée ...",
            selectedValue: null,
            availableForPro: true,
            modelName: 'motif',
            actions: {
                show: [
                    { targets: ['hasCerfa'], values: ['p', 'v'] },
                    { targets: ['eventDate'], values: ['p'] }
                ],
                hide: [
                    { targets: ['hasCerfa', 'eventDate', 'eventPlace', 'eventDescription'], values: ['d'] }
                ]
            },
            answers: [
                { type: 'button', label: 'Perte', value: 'p' },
                { type: 'button', label: 'Vol', value: 'v' },
                { type: 'button', label: 'Détérioration', value: 'd' }
            ]
        },
        {
            title: "Le client a-t-il déjà fait la déclaration de perte ou de vol ?",
            description: "Si le client a déjà le cerfa 13753-04 rempli et tamponné par la police ou le gendarmerie",
            selectedValue: null,
            availableForPro: true,
            modelName: 'hasCerfa',
            actions: {
                show: [
                    { values: [true], targets: ['eventDate'], and: { model: 'motif', values: ['p'] } },
                    { values: [false], targets: ['eventDate', 'eventPlace', 'eventDescription'] }
                ],
                hide: [
                    { values: [true], targets: ['eventDate'], and: { model: 'motif', values: ['v', 'd'] } },
                    { values: [true], targets: ['eventPlace', 'eventDescription'] }
                ]
            },

            linkedTo: [
                { modelName: 'eventDate', values: [false], },
                { modelName: 'eventPlace', values: [false] },
                { modelName: 'eventDescription', values: [false] }
            ],
            hidden: true,
            answers: [
                { type: 'button', label: 'Oui', value: true, yes: true },
                { type: 'button', label: 'Non', value: false, no: true }
            ]
        },
        {
            title: "Informations à propos de l'événement ( la perte de la carte grise ) :",
            description: "",
            selectedValue: null,
            availableForPro: true,
            modelName: 'eventDate',
            hidden: true,
            layout: 'column',
            answers: [
                { type: 'input', subType: 'date', label: 'Date de disparition' }
            ]
        },
        {
            title: "Informations à propos de l'événement :",
            description: "",
            selectedValue: null,
            availableForPro: true,
            modelName: 'eventPlace',
            hidden: true,
            layout: 'column',
            answers: [
                { type: 'input', subType: 'text', label: 'Lieu de disparition' }
            ]
        },
        {
            title: "Informations à propos de l'événement :",
            description: "",
            selectedValue: null,
            availableForPro: true,
            modelName: 'eventDescription',
            hidden: true,
            layout: 'column',
            answers: [
                { type: 'input', subType: 'text', label: 'Circonstance' }
            ]
        }
    ];

    switch (procedureType) {

        case CERTIFICAT_IMMATRICULATION:
            return questions.splice(0, 5);

        case CHANGEMENT_ADRESSE:
            {
                let ownerHosted = questions[1];
                ownerHosted.modelName = 'ownerIsHosted';
                return [questions[5], questions[1], questions[3]];
            }
        case DUPLICATA:
            {
                let ownerHosted = questions[1];
                ownerHosted.modelName = 'ownerIsHosted';
                return [].concat(ownerHosted).concat(questions.slice(6, 11));
            }
        case CERTIFICAT_IMMATRICULATION_VI:
        case CERTIFICAT_IMMATRICULATION_WW:
            return [questions[1], questions[2], questions[4]];

    }
}

function getVehicleFieldsPerProcedure(procedureType) {
    let defaultFields = [
        'registrationNum',
        'identificationNum',
        'registrationDate',
        'formulaNum',
        'firstRegistrationDate',
        'taxableHorsepower',
        'type',
        'fuelType',
        'make',
        'model'
    ];
    switch (procedureType) {
        case CERTIFICAT_IMMATRICULATION:
            return new Set(defaultFields);
        case DUPLICATA:
        case CHANGEMENT_ADRESSE:
        case DECLARATION_ACHAT:
        case DECLARATION_CESSION:
            if ([DECLARATION_CESSION, DECLARATION_ACHAT].includes(procedureType)) {
                return new Set(defaultFields.slice(0, 3).concat('make', 'saleDate', 'saleTime'));
            } else {
                return new Set(defaultFields.slice(0, 3));
            }
        case CERTIFICAT_SITUATION_ADMINISTRATIVE:
            return new Set(defaultFields.slice(0, 4));
        default:
            return new Set(defaultFields);
    }
}

function getPersonFieldsPerProcedure(personType, procedureType) {
    const addressFields = ['num', 'wayType', 'wayName', 'postalCode', 'city', 'phone'];
    switch (procedureType) {
        case DECLARATION_ACHAT:
            switch (personType) {
                case 'seller':
                    return {
                        instanceType: personType,
                        formTitle: 'Le vendeur'
                    };
                case 'buyer':
                    return {
                        instanceType: personType,
                        needAddress: true,
                        needContact: true,
                        formTitle: "L'acquéreur"
                    };
            }
        case DECLARATION_CESSION:
            switch (personType) {
                case 'seller':
                    return {
                        instanceType: personType,
                        needBirthInfos: false,
                        needAddress: false,
                        needContact: true,
                        formTitle: 'Le vendeur'
                    };
                case 'buyer':
                    return {
                        instanceType: personType,
                        needBirthInfos: false,
                        needAddress: true,
                        needContact: false,
                        formTitle: "L'acquéreur"
                    };
            }
        case CERTIFICAT_IMMATRICULATION:
            switch (personType) {
                case 'seller':
                    return {
                        instanceType: personType,
                        needBirthInfos: false,
                        needAddress: false,
                        needContact: false,
                        formTitle: 'Le vendeur'
                    };
                case 'buyer':
                    return {
                        instanceType: personType,
                        needBirthInfos: true,
                        needAddress: true,
                        needContact: true,
                        needResidenceDepartment: true,
                        formTitle: "L'acquéreur"
                    };
            }
            break;
        case DUPLICATA:
            return {
                instanceType: personType,
                needBirthInfos: false,
                needAddress: true,
                needContact: true,
                formTitle: 'Le propriétaire'
            };
        case CHANGEMENT_ADRESSE:
            return {
                instanceType: personType,
                needBirthInfos: true,
                needAddress: true,
                needContact: true,
                needOldAddress: true,
                formTitle: 'Le propriétaire'
            };
            break;
        case CERTIFICAT_IMMATRICULATION_WW:
        case CERTIFICAT_IMMATRICULATION_VI:
            switch (personType) {
                case 'buyer':
                    return {
                        instanceType: personType,
                        needBirthInfos: true,
                        needAddress: true,
                        needContact: true,
                        formTitle: "L'acquéreur"
                    };
            }
            break;
    }
}

function getProcedureStepAction(procedureType, step) {
    /*[
     'start', 'seller', 'buyer', 'vehicle', 'payment', 
     'questions', 'documents', 'signature', 'end', 
     'owner', 'co-buyers'
     ]*/
    /*    
     createProcedure {{api_endpoint}}procedures/new
     updateProcedure {{api_endpoint}}procedures/:id/:type/:step
     createProcedurePayment {{api_endpoint}}procedures/:id/payment
     {{api_endpoint}}procedures/:id/create-payment-request/
     getProcedureDocuments {{api_endpoint}}procedures/:id/documents/:type
     createProcedureSign {{api_endpoint}}procedures/:id/sign/:type
     */
    /*
     
     CIM :
     start 			: {{api_endpoint}}procedures/new
     seller + buyer          : {{api_endpoint}}procedures/:id/:type/:step
     vehicle 		: {{api_endpoint}}procedures/:id/:type/:step
     questions		: {{api_endpoint}}procedures/:id/:type/:step    
     payment			: {{api_endpoint}}procedures/:id/payment
     {{api_endpoint}}procedures/:id/create-payment-request/
     documents		: {{api_endpoint}}procedures/:id/documents/:type
     signature		: {{api_endpoint}}procedures/:id/sign/:type
     
     CAD : 
     start 			: {{api_endpoint}}procedures/new
     owner + 2 add           : {{api_endpoint}}procedures/:id/:type/:step
     vehicle 		: {{api_endpoint}}procedures/:id/:type/:step
     questions		: {{api_endpoint}}procedures/:id/:type/:step
     documents		: {{api_endpoint}}procedures/:id/documents/:type
     payment			: {{api_endpoint}}procedures/:id/price/:type
     {{api_endpoint}}procedures/:id/payment
     {{api_endpoint}}procedures/:id/create-payment-request/
     signature		: {{api_endpoint}}procedures/:id/sign/:type
     
     DC / DA : 
     start 			: {{api_endpoint}}procedures/new
     sel/buyer + veh         : {{api_endpoint}}procedures/:id/:type
     documents		: {{api_endpoint}}procedures/:id/documents/:type
     signature		: {{api_endpoint}}procedures/:id/sign/:type
     payment			: {{api_endpoint}}procedures/:id/price/:type
     {{api_endpoint}}procedures/:id/payment
     {{api_endpoint}}procedures/:id/create-payment-request/
     
     DUP :
     start 			: {{api_endpoint}}procedures/new
     owner			: {{api_endpoint}}procedures/:id/:type/:step
     vehicle 		: {{api_endpoint}}procedures/:id/:type/:step
     payment			: {{api_endpoint}}procedures/:id/price/:type
     {{api_endpoint}}procedures/:id/payment
     {{api_endpoint}}procedures/:id/create-payment-request/
     questions		: {{api_endpoint}}procedures/:id/:type/:step	
     documents		: {{api_endpoint}}procedures/:id/documents/:type
     signature		: {{api_endpoint}}procedures/:id/sign/:type
     
     CIWW / CIVI :
     start 			: {{api_endpoint}}procedures/new
     buyer			: {{api_endpoint}}procedures/:id/:type/:step
     ques / cobuyers         : {{api_endpoint}}procedures/:id/:type/:step
     documents		: {{api_endpoint}}procedures/:id/documents/:type
     payment			: {{api_endpoint}}procedures/:id/price/:type
     {{api_endpoint}}procedures/:id/payment
     {{api_endpoint}}procedures/:id/create-payment-request/
     signature		: {{api_endpoint}}procedures/:id/sign/:type
     
     CSA :
     start 			: {{api_endpoint}}procedures/new
     owner + vehicle         : {{api_endpoint}}procedures/:id/:type/:step
     payment			: {{api_endpoint}}procedures/:id/price/:type
     {{api_endpoint}}procedures/:id/payment
     {{api_endpoint}}procedures/:id/create-payment-request/
     processing		: {{api_endpoint}}procedures/:id/:type/:step
     
     */
    switch (procedureType) {

        case CERTIFICAT_IMMATRICULATION:
            switch (step) {
                case 'start':
                    return {
                        items: ['procedureType', 'forAnotherPerson'],
                        method: 'createProcedure'
                    };
                case 'buyer':
                    return {
                        step: 1,
                        items: ['seller', 'buyer'],
                        method: 'updateProcedure'
                    };
                case 'vehicle':
                    return {
                        step: 2,
                        items: ['vehicle', 'codeDep'],
                        method: 'updateProcedure'
                    };
                case 'questions':
                    return {
                        step: 3,
                        items: [
                            'vehicleIsInsured', 'buyerHasOldPermis', 'buyerIsHosted',
                            'vehicleFromPro', 'hasCoBuyers', 'coBuyers'
                        ],
                        method: 'updateProcedure'
                    };
                case 'cobuyers':
                    return {
                        step: 3,
                        items: [
                            'vehicleIsInsured', 'buyerHasOldPermis', 'buyerIsHosted',
                            'vehicleFromPro', 'hasCoBuyers', 'coBuyers'
                        ],
                        method: 'updateProcedure'
                    };
                default:
                    return;
            }
        case CHANGEMENT_ADRESSE:
            switch (step) {
                case 'start':
                    return {
                        items: ['procedureType', 'forAnotherPerson'],
                        method: 'createProcedure'
                    };
                case 'owner':
                    return {
                        step: 1,
                        items: ['owner'],
                        method: 'updateProcedure'
                    };
                case 'vehicle':
                    return {
                        step: 2,
                        items: ['vehicle'],
                        method: 'updateProcedure'
                    };
                case 'questions':
                    return {
                        step: 3,
                        items: ['ownerIsHosted', 'has4Changes', 'vehicleIsInsured'],
                        method: 'updateProcedure'
                    };
                default:
                    return;
            }
        case DUPLICATA:
            switch (step) {
                case 'start':
                    return {
                        items: ['procedureType', 'forAnotherPerson'],
                        method: 'createProcedure'
                    };
                case 'owner':
                    return {
                        step: 1,
                        items: ['owner'],
                        method: 'updateProcedure'
                    };
                case 'vehicle':
                    return {
                        step: 2,
                        items: ['vehicle'],
                        method: 'updateProcedure'
                    };
                case 'questions':
                    return {
                        step: 3,
                        items: [
                            'motif', 'hasCerfa', 'eventDate', 'eventPlace',
                            'eventDescription', 'ownerIsHosted'
                        ],
                        method: 'updateProcedure'
                    };
                default:
                    return;
            }
        case DECLARATION_ACHAT:
        case DECLARATION_CESSION:
            switch (step) {
                case 'start':
                    return {
                        items: ['procedureType', 'forAnotherPerson'],
                        method: 'createProcedure'
                    };
                case 'vehicle':
                    return {
                        step: 1,
                        items: ['seller', 'buyer', 'vehicle', 'saleDate', 'saleTime'],
                        method: 'updateProcedure'
                    };
                default:
                    return;
            }
        case CERTIFICAT_IMMATRICULATION_WW:
        case CERTIFICAT_IMMATRICULATION_VI:
            switch (step) {
                case 'start':
                    return {
                        items: ['procedureType', 'forAnotherPerson'],
                        method: 'createProcedure'
                    };
                case 'buyer':
                    return {
                        step: 1,
                        items: ['buyer'],
                        method: 'updateProcedure'
                    };
                case 'questions':
                case 'cobuyers':
                    return {
                        step: 2,
                        items: [
                            'buyerIsHosted', 'vehicleFromPro', 'hasCoBuyers',
                            'coBuyers'
                        ],
                        method: 'updateProcedure'
                    };
                default:
                    return;
            }
    }
}

function getConcernedPersonType(procedureType) {
    switch (procedureType) {
        case CERTIFICAT_IMMATRICULATION:
        case CERTIFICAT_IMMATRICULATION_WW:
        case CERTIFICAT_IMMATRICULATION_VI:
            return 'buyer';
        case DUPLICATA:
        case CHANGEMENT_ADRESSE:
        case FICHE_IDENTIFICATION_VEHICULE:
        case CERTIFICAT_SITUATION_ADMINISTRATIVE:
            return 'owner';
        case DECLARATION_ACHAT:
            return 'buyer';
        case DECLARATION_CESSION:
            return 'seller';
    }
    return null;
}

function getStepIndex(procedureType, step) {

    console.log('>>> getStepIndex :', step)

    if (step === 'cobuyers' && PROCEDURES_WITH_COBUYERS.includes(procedureType)) {
        step = 'questions';
    }
    const procedureSteps = getProcedureStepsNames(procedureType);
    console.log('>>> step index :', procedureSteps.indexOf(step), 'error');

    return procedureSteps.indexOf(step);

    console.log('>>> procedureSteps :', procedureSteps, 'error');

    if (procedureSteps) {
        return procedureSteps.indexOf(step);
    }
}

function gotoStep(procedureType, currentStep, next = true) {

    let steps = [
        'start',
        'seller', 'buyer', 'owner', 'co-buyers',
        'vehicle',
        'payment',
        'questions', 'documents',
        'signature'
    ];

    if (currentStep === 'cobuyers') {
        currentStep = next ? 'questions' : 'documents';
    }
    let procedureSteps = getProcedureStepsNames(procedureType);
    if (procedureSteps) {
        let i = procedureSteps.indexOf(currentStep);
        if (next === true) {
            if (i < procedureSteps.length - 1) {
                return procedureSteps[i + 1];
            }
        } else {
            if (i > 1) {
                return procedureSteps[i - 1];
            }
        }
    }
    return null;
}

function createFilterFor(query = '') {
    query = query.toLowerCase();
    return (w) => w.short.toLowerCase().indexOf(query) === 0 || w.long.toLowerCase().indexOf(query) === 0;
}

function setInputsEvents() {
    document.querySelectorAll('[data-numeric-only]').forEach(input => {

        input.oninput = function (event) {
            let cursorPosition = this.selectionStart;
            let inputValue = this.value;
            let sanitizedValue = inputValue.replace(/\D/g, '');
            let newCursorPosition = cursorPosition - (inputValue.length - sanitizedValue.length);
            this.value = sanitizedValue;
            this.setSelectionRange(newCursorPosition, newCursorPosition);
        };

        /*input.addEventListener('keydown', function (event) {            
         //if ((/*event.keyCode >= 48 && 
         if(event.keyCode <= 57 && event.keyCode !== 32) || (event.keyCode >= 96 && event.keyCode <= 105)) {
         return true;
         }
         event.preventDefault();
         return false;
         });*/
    });
    document.querySelectorAll('[data-alpha-only]').forEach(input => {

        input.oninput = function (event) {
            let cursorPosition = this.selectionStart;
            let inputValue = this.value;
            if (/\d/.test(inputValue)) {
                let sanitizedValue = inputValue.replace(/\d/g, '');
                let newCursorPosition = cursorPosition - (inputValue.length - sanitizedValue.length);
                this.value = sanitizedValue;
                this.setSelectionRange(newCursorPosition, newCursorPosition);
            }
        };

        /*input.addEventListener('keydown', function (event) {
         if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
         event.preventDefault();
         return false;
         }
         return true;
         });*/
    });
}

//------------------------------------------------------------------------------

angular.module(config.app.name).component('procedureWizard', {
    templateUrl: 'components/procedure/procedure.wizard.component.html',
    styleUrls: ['procedure.wizard.component.css'],
    controller: function ($scope, $rootScope, $timeout, $location, $cookies,
        toast, sessionStorageService, procedureTools, $_procedures) {

        let webAppToken = $cookies.get(config.app.cookies.webappToken);
        if (!webAppToken) {
            $location.path('/');
        }

        var self = this;

        /*$scope.$on('$destroy', function () {
         console.log('Component unloaded', 'error', 't:: procedure wizard');
         let el =  document.querySelector("[data-component='procedure-wizard']");
         if(el){
         document.head.removeChild(el);
         }
         });*/

        self.user = $rootScope.currentUser;
        self.procedureTypes = procedureTools.getAllProcedureTypes();

        self.currentTab = 0;

        //------------------------------------------------------------------------------

        self.onEventListener = function ($event) {
            switch ($event.type) {
                case EVENTS.COMPONENT_LOAD:
                    setInputsEvents();
                    break;
                case EVENTS.NAVIGATION:
                    if ($event.detail === 'back') {
                        self.gotoPrev();
                    } else if ($event.detail === 'next') {
                        self.gotoNext();
                    }
                    break;
            }
        };

        function __init() {

            console.log('INIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIT')

            self.navSens = sessionStorageService.get('nav-sens') || 'r';

            self.procedureId = sessionStorageService.get('procedureId') || null;
            self.procedureType = sessionStorageService.get('procedureType') || null;

            self.paid = sessionStorageService.get('paid') || false;
            self.signed = sessionStorageService.get('signed') || false;

            self.procedureSteps = sessionStorageService.get('procedureSteps') || getProcedureSteps(self.procedureType);

            self.currentNavigationStep = sessionStorageService.get('currentNavigationStep') || 'start';

            self.stepIndex = getStepIndex(self.procedureType, self.currentNavigationStep);

            self.canGoBack = self.stepIndex > 1;

            self.currentProcedureStep = sessionStorageService.get('currentProcedureStep') || 'start';

            self.procedure = sessionStorageService.get('procedure') || null;
            self.codeDep = sessionStorageService.get('codeDep') || null;
            self.personType = null;

            self.seller = sessionStorageService.get('seller') || { type: 'seller' };
            self.buyer = sessionStorageService.get('buyer') || { type: 'buyer' };
            self.owner = sessionStorageService.get('owner') || { type: 'owner' };
            self.coBuyers = sessionStorageService.get('coBuyers') || [];

            self.vehicle = sessionStorageService.get('vehicle') || {};

            self.saleDate = sessionStorageService.get('saleDate') || null;
            self.saleTime = sessionStorageService.get('saleTime') || null;
            self.forAnotherPerson = sessionStorageService.get('forAnotherPerson');
            self.proxyProcedure = self.forAnotherPerson === true;

            self.concernedPersonType = getConcernedPersonType(self.procedureType);
            self.concernedPerson = self[self.concernedPersonType];

            if (['seller', 'buyer', 'owner'].includes(self.currentNavigationStep)) {
                $timeout(() => {
                    self.setPersonType(self.currentNavigationStep);
                });
            }

            $timeout(() => $scope.$apply());

        }

        self.$onInit = function () {
            __init();
        };

        self.stepIsDone = function (step) {
            if (!self.procedureSteps) {
                return false;
            }
            let indexCurrentStep = self.procedureSteps.indexOf(self.currentProcedureStep);
            let indexStep = self.procedureSteps.indexOf(step);
            if (indexStep < 0) {
                return false;
            }
            return indexStep <= indexCurrentStep;
        }

        self.getData = function (type, cb) {
            if (!type || typeof cb !== 'function') {
                return;
            }
            if (typeof type === 'string') {
                if (type in self) {
                    return cb(self[type]);
                }
            } else if (Array.isArray(type)) {
                let a = [];
                type.forEach((t) => {
                    if (t in self) {
                        a.push(self[t]);
                    } else {
                        a.push(undefined);
                    }
                });
                return cb(a);
            }
            cb(null);
        };

        // receive data from child component
        self.onDataReceived = function (type, data) {

            console.log('>>> received data type :', type);
            console.log('>>> received data :', data);
            console.log('>>> current step :', self.currentNavigationStep);

            switch (type) {
                case 'procedure-start':

                    sessionStorageService.clear();

                    self.__set('procedureType', data.procedureType);
                    self.__set('forAnotherPerson', data.forAnotherPerson);
                    self.__set('procedureSteps', getProcedureSteps(data.procedureType));

                    {
                        let action = getProcedureStepAction(self.procedureType, self.currentNavigationStep);
                        let data = null;
                        if (action) {
                            if (action.items && action.items.length > 0) {
                                data = {};
                                action.items.forEach((item) => {
                                    data[item] = self[item];
                                });
                            }
                            self.__requests.run(action.method, data, action.step).then((procedure) => {
                                self.setProcedure(procedure);
                                self.gotoProcedureNextStep();
                            });
                        }
                    }
                    //self.currentTab++;

                    //self.__set('procedureSteps', PROCEDURES_STEPS[self.procedureType]);

                    break;

                case 'seller':
                case 'buyer':
                case 'owner':

                    console.log('ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ');
                    console.log('####################################');

                    self.setPerson(type, data.person);
                    {
                        let action = getProcedureStepAction(self.procedureType, self.currentNavigationStep);
                        let data = null;
                        if (action) {
                            const tempFields = [
                                'instanceType', 'needBirthInfos', 'needAddress',
                                'needContact', 'formTitle', 'needResidenceDepartment',
                                'action'
                            ];
                            if (action.items && action.items.length > 0) {
                                data = {};
                                action.items.forEach((item) => {
                                    data[item] = self.cleanData(self[item]);
                                });
                            }
                            self.__requests.run(action.method, data, action.step).then((res) => {
                                self.gotoProcedureNextStep();
                            }).catch((err) => {
                            });
                        } else {
                            self.gotoProcedureNextStep();
                        }
                    }
                    //self.currentTab++
                    break;

                case 'vehicle':

                    //data.vehicle.registrationNum = 'AB-999-BA'

                    if (self.paid === true) {
                        toast.show("La démarche a déjà été payée, alors il n'est plus possible de changer les informations de véhicule." +
                            " Il faut nous contacter pour pouvoir le faire.", 'error');
                        self.gotoNext();
                        return;
                    }

                    self.setVehicle(data.vehicle);

                    if (data.saleDate && data.saleTime) {
                        self.__set('saleDate', data.saleDate);
                        self.__set('saleTime', data.saleTime);
                    }
                    {
                        let action = getProcedureStepAction(self.procedureType, self.currentNavigationStep);
                        let data = null;
                        if (action) {
                            if (action.items && action.items.length > 0) {
                                data = {};
                                action.items.forEach((item) => {
                                    data[item] = self.cleanData(self[item]);
                                });
                            }
                            self.__requests.run(action.method, data, action.step).then(() => {
                                self.gotoProcedureNextStep();
                            });
                        }
                    }
                    break;
                case 'payment':
                    self.__set('paid', (data.payment.paid));
                    self.gotoProcedureNextStep();
                    break;
                case 'paid':
                    self.__set('paid', (data.paid));
                    break;

                case 'question':
                    self.__set(data.key, (data.value));
                    break;

                case 'questions':
                    for (let i in data.questions) {
                        self.__set(i, data.questions[i]);
                    }
                    if (self.hasCoBuyers === true) {
                        return self.setCurrentStep('cobuyers', true);
                    }
                    {
                        let action = getProcedureStepAction(self.procedureType, self.currentNavigationStep);
                        let data = null;
                        if (action) {
                            if (action.items && action.items.length > 0) {
                                data = {};
                                action.items.forEach((item) => {
                                    data[item] = self[item];
                                });
                            }
                            self.__requests.run(action.method, data, action.step).then(() => {
                                self.gotoProcedureNextStep();
                            });
                        }
                    }
                    break;

                case 'documents':
                    let action = getProcedureStepAction(self.procedureType, self.currentNavigationStep);
                    if (!action) {
                        self.gotoProcedureNextStep();
                    }
                    break;

                case 'signed':
                    self.__set('signed', (data.signed));
                    break;

                case 'signature':
                    {
                        self.__set('signed', (data.signed));
                        let action = getProcedureStepAction(self.procedureType, self.currentNavigationStep);
                        if (!action) {
                            self.gotoProcedureNextStep();
                        }
                    }
                    break;

                case 'documentsToSign':
                    self.__set('documentsToSign', (data.documentsToSign));
                    break;

                case 'residenceDepartment':
                    self.__set('codeDep', (data.residenceDepartment));
                    break;

                case 'saleDateTime':
                    self.__set('saleDate', (data.saleDate));
                    self.__set('saleTime', (data.saleTime));
                    break;

                case 'coBuyer':
                    if (Array.isArray(data.coBuyers)) {
                        let coBuyers = [];
                        data.coBuyers.forEach((coBuyer) => {
                            coBuyer.instanceType = 'coBuyer';
                            coBuyers.push(self.cleanData(coBuyer));
                        });
                        self.__set('coBuyers', coBuyers);
                    } else if (data.coBuyers === null) {
                        self.__set('coBuyers', []);
                    }
                    break;
                case 'coBuyers':
                    if (data.coBuyers === null) {
                        self.__set('coBuyers', []);
                        self.__set('hasCoBuyers', false);
                    }
                    {

                        console.log('--------------------------------------------');
                        console.log(self.procedureType, self.currentNavigationStep);

                        let action = getProcedureStepAction(self.procedureType, self.currentNavigationStep);

                        console.log('>>> action :', action);

                        let data = null;
                        if (action) {
                            if (action.items && action.items.length > 0) {
                                data = {};
                                action.items.forEach((item) => {
                                    let d = sessionStorageService.get(item);
                                    data[item] = d !== null ? d : self[item];
                                });
                            }
                            self.__requests.run(action.method, data, action.step).then(() => {
                                self.gotoProcedureNextStep();
                            });
                        }
                    }
                    break;
                case 'end':
                    self.cancelProcedure(false);
                    break;
            }

        };

        self.gotoProcedureNextStep = function () {
            const r = setStepAsDone(self.procedureSteps, self.currentNavigationStep);
            self.__set('procedureSteps', r.procedureSteps);
            self.gotoNext(true);
        }

        self.cleanData = function (input) {
            if (typeof input !== 'object') {
                return input;
            }
            let output = Object.assign({}, input);
            if (output.instanceType && ['seller', 'buyer', 'owner', 'coBuyer'].includes(output.instanceType)) {
                delete output.instanceType;
                delete output.needBirthInfos;
                delete output.needAddress;
                delete output.needContact;
                delete output.formTitle;
                delete output.needOldAddress;
                delete output.needResidenceDepartment;
                delete output.$$hashKey;
                if (output.type === PERSONNE_MORALE) {
                    delete output.title;
                    delete output.firstname;
                    delete output.lastname;
                    delete output.marriedname;
                    delete output.birthData;
                    delete output.birthPlace;
                    delete output.birthDepartment;
                    delete output.birthCountry;
                } else {
                    delete output.siren;
                    delete output.company;
                }
                return output;
            }
            if ('registrationNum' in output) { // vehicle
                for (let i in output) {
                    if (moment.isMoment(output[i])) {
                        output[i] = output[i].format('DD/MM/YYYY');
                    }
                }
            }
            return output;
        };

        // api requests
        self.__requests = {
            run: function (req, ...args) {
                if (typeof this[req] === 'function') {
                    return this[req](args);
                }
            },
            createProcedure: function () {
                if (self.procedureType === null) {
                    return Promise.reject('NoData');
                }
                return new Promise((resolve, reject) => {
                    $_procedures.createProcedure({
                        type: self.procedureType,
                        forAnotherPerson: self.forAnotherPerson
                    }).then((res) => {
                        resolve(res.Procedure);
                    }).catch((err) => {
                        console.error('create new procedure error :');
                        console.log(err);
                        toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                        reject(err);
                    });
                });
            },
            updateProcedure: function (params) {
                let data = params[0], step = params[1];
                if (self.procedureId === null || self.procedureType === null) {
                    return Promise.reject('NoData');
                }
                return new Promise((resolve, reject) => {
                    $_procedures.updateProcedure(data, self.procedureId, self.procedureType, step).then((res) => {
                        console.log('>>> updated procedure res :', res);
                        resolve(res);
                    }).catch((err) => {
                        console.error('update procedure error :');
                        console.log(err);
                        toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                        reject(err);
                    });
                });
            }
        };

        // setters
        self.__set = function (field, value, storeIt = true) {
            self[field] = value;
            if (storeIt === true) {
                sessionStorageService.set(field, value);
            }//
        };

        function initProcedure() {
            self.__set('paid', false);
            self.__set('signed', false);
        }

        self.setProcedure = function (procedure) {
            self.procedure = procedure;
            sessionStorageService.set('procedure', procedure);
            self.setProcedureId(procedure._id);
            self.setProcedureType(procedure.type);
            initProcedure();
        };
        self.setProcedureId = function (procedureId) {
            self.procedureId = procedureId;
            sessionStorageService.set('procedureId', self.procedureId);
        };
        self.setProcedureType = function (procedureType) {
            self.procedureType = procedureType;
            sessionStorageService.set('procedureType', self.procedureType);
        };

        self.setPerson = function (personType, person) {
            self[personType] = person;
            sessionStorageService.set(personType, person);
        };
        self.setVehicle = function (vehicle) {
            self.vehicle = vehicle;
            sessionStorageService.set('vehicle', vehicle);
        };

        self.setPersonType = function (personType) {
            self.personType = personType;
            let person = self[self.personType];
            let params = getPersonFieldsPerProcedure(self.personType, self.procedureType);
            Object.assign(person, params);
        };
        self.setCurrentStep = function (step, persistProcedureStep = false) {

            console.log('>>> setCurrentStep', step, 'warning');

            self.currentNavigationStep = step;
            self.stepIndex = getStepIndex(self.procedureType, self.currentNavigationStep);
            self.canGoBack = self.stepIndex > 1;

            sessionStorageService.set('currentNavigationStep', self.currentNavigationStep);
            if (persistProcedureStep === true) {
                self.__set('currentProcedureStep', self.currentNavigationStep, true);
                $timeout(() => $scope.$apply());
            }
            $timeout(() => $scope.$apply());
        };

        self.goto = function (next = true, persistProcedureStep = false) {
            let step = self.currentNavigationStep;
            let newStep = gotoStep(self.procedureType, self.currentNavigationStep, next);

            if (['seller', 'buyer', 'owner'].includes(newStep)) {
                self.setPersonType(newStep);
            } else {
                self.personType = null;
            }
            sessionStorageService.set('nav-sens', next ? 'r' : 'l');

            self.setCurrentStep(newStep, persistProcedureStep);

            self.navSens = sessionStorageService.get('nav-sens') || 'r';
        };

        self.gotoNext = function (persistProcedureStep = false) {
            self.goto(true, persistProcedureStep);
        };
        self.gotoPrev = function (step = null) {
            self.goto(false);
        };

        self.cancelProcedure = function (withConfirmation = true) {
            let _confirm = true;
            if (withConfirmation) {
                _confirm = confirm('Voulez-vous vraiment abandonner la démarche en cours ?');
            }
            //if (confirm('Voulez-vous vraiment abandonner la démarche en cours ?')) {
            if (_confirm) {
                sessionStorageService.clear();
                self.setProcedureType(null);
                self.setCurrentStep('start', true);
                __init();
                $timeout(() => $scope.$apply());
            }//
            //}
        };
    }
});